import UserDto from "../dtos/user.dto";

/**
 * This will trigger in routing before the view was seen.
 * Check the user had specific policy to view the route.
 *
 * @param policies
 * @param user
 * @returns boolean
 */
const hasAccess = (policies?: Array<string[]>, user?: UserDto): Boolean => {
	if (!policies) {
		return true;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [roles, permissions] = policies;

	const hasRole: Boolean =
		roles.includes("*") ||
		roles.some((role) => role === String(user?.role).toLowerCase());

	// Might need later but for now,
	// once user had the authorized roles, he/she can read or write
	const hasPermissions: Boolean =
		permissions.includes("*") ||
		permissions.some((pm) => user?.permissions[pm]);

	return hasRole;
};

export default hasAccess;
