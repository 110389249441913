import { lazy } from "react";

export const Login = lazy(() => import("./Login/Login"));

export const Dashboard = lazy(() => import("./Dashboard/Dashboard"));

export const ForgotPassword = lazy(() =>
	import("./ForgotPassword/ForgotPassword")
);

export const ResetPassword = lazy(() =>
	import("./ResetPassword/ResetPassword")
);

export const MyAccount = lazy(() => import("./MyAccount/MyAccount"));

export const MyNotifications = lazy(() =>
	import("./MyAccount/MyNotifications")
);

export const ChangePassword = lazy(() =>
	import(
		"./AdminManagement/ChangePasswordFormBuilder/ChangePasswordFormBuilder"
	)
);

export const MasterAuditTrail = lazy(() =>
	import("./AdminManagement/MasterAuditTrail/MasterAuditTrail")
);

export const MasterData = lazy(() =>
	import("./AdminManagement/MasterDataBuilder/MasterDataBuilder")
);

export const CMSAccounts = lazy(() =>
	import("./CMSAccountManagement/CMSAccountManagement")
);

export const AddCMSAccount = lazy(() =>
	import("./CMSAccountManagement/CreateCMSAccount")
);

export const EditCMSAccountDetails = lazy(() =>
	import("./CMSAccountManagement/EditCMSAccount")
);

export const TermsAndConditions = lazy(() =>
	import("./ContentManagement/TermsAndCondition/TermsAndCondition")
);
export const PrivacyPolicy = lazy(() =>
	import("./ContentManagement/PrivacyPolicy/PrivacyPolicy")
);
export const AboutUs = lazy(() =>
	import("./ContentManagement/AboutUs/AboutUs")
);

//faq 
export const FAQListing = lazy(() => import("./FAQManagement/FAQListing"));
export const AddFAQ = lazy(() => import("./FAQManagement/AddFAQ/AddFAQ"));
export const EditFAQ = lazy(() => import("./FAQManagement/EditFAQ/EditFAQ"));

//Mood Group

export const MoodGroupListing = lazy(() =>
	import("./MoodGroupManagement/MoodGroupsListing/MoodGroupListing")
);
export const AddMoodGroup = lazy(() =>
	import("./MoodGroupManagement/AddMoodGroup/AddMoodGroup")
);
export const AddMood = lazy(() => import("./MoodGroupManagement/AddMood/AddMood"));
export const EditMood = lazy(() => import("./MoodGroupManagement/EditMood/EditMood"));
export const MoodGroupDetail = lazy(() =>
	import("./MoodGroupManagement/MoodGroupDetail/MoodGroupDetail")
);
export const UserListing = lazy(() =>
	import("./UserManagement/UserListing/UserListing")
);
export const UserDetail = lazy(() =>
	import("./UserManagement/UserDetail/UserDetail")
);

//Activity Management
export const ActivityListing = lazy(() =>
	import("./ActivitiyManagement/ActivityManagmentListing/ActivityManagement")
);
export const ActivityDetail = lazy(() =>
	import("./ActivitiyManagement/ActivityDetail/ActivityDetail")
);
export const AddActivity = lazy(() =>
	import("./ActivitiyManagement/AddActivity/AddActivity")
);
export const AddSubActivity = lazy(() =>
	import("./ActivitiyManagement/AddSubActivity/AddSubActivity")
);
export const EditSubActivity = lazy(() =>
	import("./ActivitiyManagement/EditSubActivity/EditSubActivity")
);

//Sticker Pack Management
export const StickerPackListing = lazy(() =>
	import("./StickerPackManagement/StickerPackListing/StickerPackListing")
);
export const StickerPackDetail = lazy(() =>
	import("./StickerPackManagement/StickerPackDetail/StickerPackDetail")
);
export const AddStickerPack = lazy(() =>
	import("./StickerPackManagement/AddStickerPack/AddStickerPack")
);

export const AddSticker = lazy(() =>
	import("./StickerPackManagement/AddSticker/AddSticker")
);

export const EditSticker = lazy(() =>
	import("./StickerPackManagement/EdtiSticker/EditSticker")
);

//Current Moods Management
export const CurrentMoodListing = lazy(() =>
	import("./CurrentMoodManagement/CurrentMoodListing/CurrentMoodListing")
);

export const CurrentMoodDetail = lazy(() =>
	import("./CurrentMoodManagement/CurrentMoodDetail/CurrentMoodDetail")
);

export const AddCurrentMood = lazy(() =>
	import("./CurrentMoodManagement/AddCurrentMood/AddCurrentMood")
);

//Survey
export const SurveyStatistics = lazy(() =>
	import("./SurveyManagement/Statistics/SurveyStatistics")
);
export const SurveyListing = lazy(() =>
	import("./SurveyManagement/SurveyLIsting/SurveyListing")
);

export const SurveyDetail = lazy(() =>
	import("./SurveyManagement/SurveyDetail/SurveyDetail")
);

//Random Message Management
export const FortuneCookieListing = lazy(() =>
	import("./FortuneCookieManagement/FortuneCookieListing/FortuneCookieListing")
);

export const FortuneCookieDetail = lazy(() =>
	import("./FortuneCookieManagement/FortuneCookieDetail/FortuneCookieDetail")
);

export const AddFortuneCookie = lazy(() =>
	import("./FortuneCookieManagement/AddFortuneCookie/AddFortuneCookie")
);

//Ads
export const DirectoryAdsListing = lazy(() =>
	import("./AdsManagement/Directory/List")
);

export const DirectoryAdsDetail = lazy(() =>
	import("./AdsManagement/Directory/Details")
);

export const DirectoryAddAds = lazy(() =>
	import("./AdsManagement/Directory/Add")
);

export const SelfCareAdsListing = lazy(() =>
	import("./AdsManagement/SelfCare/List")
);

export const SelfCareAdsDetail = lazy(() =>
	import("./AdsManagement/SelfCare/Details")
);

export const SelfCareAddAds = lazy(() =>
	import("./AdsManagement/SelfCare/Add")
);

export const EventAdsListing = lazy(() =>
	import("./AdsManagement/Event/List")
);

export const EventAdsDetail = lazy(() =>
	import("./AdsManagement/Event/Details")
);

export const EventAddAds = lazy(() =>
	import("./AdsManagement/Event/Add")
);

//Splashscreen
export const SplashscreenListing = lazy(() =>
	import("./SplashscreenManagement/SplashscreenListing/SplashscreenListing")
);

export const SplashscreenDetail = lazy(() =>
	import("./SplashscreenManagement/SplashscreenDetail/SplashscreenDetail")
);

export const AddSplashscreen = lazy(() =>
	import("./SplashscreenManagement/AddSplashscreen/AddSplashscreen")
);

//Event
export const EventListing = lazy(() =>
	import("./EventManagement/EventListing/EventListing")
);

export const EventDetail = lazy(() =>
	import("./EventManagement/EventDetail/EventDetail")
);

export const AddEvent = lazy(() =>
	import("./EventManagement/AddEvent/AddEvent")
);

//Selfcare tag Management
export const EventTag = lazy(() =>
	import("./EventManagement/TagListing/TagListing")
);
export const EventTagDetail = lazy(() =>
	import("./EventManagement/TagDetail/TagDetail")
);
export const EventAddTag = lazy(() =>
	import("./EventManagement/AddTag/AddTag")
);

//Selfcare category Management
export const EventCategory = lazy(() =>
	import("./EventManagement/CategoryListing/CategoryListing")
);
export const EventCategoryDetail = lazy(() =>
	import("./EventManagement/CategoryDetail/CategoryDetail")
);
export const EventAddCategory = lazy(() =>
	import("./EventManagement/AddCategory/AddCategory")
);
export const EventAddSubCategory = lazy(() =>
	import("./EventManagement/AddSubCategory/AddSubCategory")
);
export const EventEditSubCategory = lazy(() =>
	import("./EventManagement/EditSubCategory/EditSubCategory")
);

//SelfCare
export const SelfCareListing = lazy(() =>
	import("./SelfCareManagement/SelfCareListing/SelfCareListing")
);

export const SelfCareDetail = lazy(() =>
	import("./SelfCareManagement/SelfCareDetail/SelfCareDetail")
);

export const AddSelfCare = lazy(() =>
	import("./SelfCareManagement/AddSelfCare/AddSelfCare")
);

//Selfcare tag Management
export const SelfCareTag = lazy(() =>
	import("./SelfCareManagement/TagListing/TagListing")
);
export const SelfCareTagDetail = lazy(() =>
	import("./SelfCareManagement/TagDetail/TagDetail")
);
export const SelfCareAddTag = lazy(() =>
	import("./SelfCareManagement/AddTag/AddTag")
);

//Selfcare category Management
export const SelfCareCategory = lazy(() =>
	import("./SelfCareManagement/CategoryListing/CategoryListing")
);
export const SelfCareCategoryDetail = lazy(() =>
	import("./SelfCareManagement/CategoryDetail/CategoryDetail")
);
export const SelfCareAddCategory = lazy(() =>
	import("./SelfCareManagement/AddCategory/AddCategory")
);
export const SelfCareAddSubCategory = lazy(() =>
	import("./SelfCareManagement/AddSubCategory/AddSubCategory")
);
export const SelfCareEditSubCategory = lazy(() =>
	import("./SelfCareManagement/EditSubCategory/EditSubCategory")
);

export const PushNotifications = lazy(() =>
	import("./NotificationManagement/NotificationListing/NotificationListing")
);

export const CreatePushNotification = lazy(() =>
	import("./NotificationManagement/AddNotification/AddNotification")
);

export const PushNotificationDetails = lazy(() =>
	import("./CMSAccountManagement/EditNotification")
);
