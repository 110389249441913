import { createReducer } from "typesafe-actions";
import { REHYDRATE, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { actionDetail } from "../utils/misc";
import {
	authLoginAsyncAction,
	authLogoutAction,
	authProfileAsyncAction,
} from "../actions/authAction";
import AuthReducerDto from "../dtos/auth-reducer.dto";

const initialState: AuthReducerDto = {
	authenticated: false,
	token: "",
	profile: {},
	permissions: {},
	login: {
		loading: false,
		error: "",
	},
	refreshToken: "",
	expiryDatetime: "",

	action: actionDetail,
};

const authReducer: any = persistReducer(
	{
		key: "auth",
		storage,
		whitelist: ["token", "profile", "permissions", "expiryDatetime"],
	},

	createReducer(initialState)
		.handleAction(REHYDRATE, (state: any, action: any): any => {
			if (action.key !== "auth") return state;
			return {
				...state,
				authenticated: !!(action.payload && action.payload.token),
			};
		})
		.handleAction(authLoginAsyncAction.request, (state: any): any => ({
			...state,
			login: { ...initialState.login, loading: true },
		}))
		.handleAction(
			authLoginAsyncAction.success,
			(state: any, { payload }: any) => ({
				...state,
				token: payload.accessToken,
				refreshToken: payload.refreshToken,
				expiryDatetime: payload.expiryDatetime,
				authenticated: true,
				login: {
					loading: false,
					error: "",
				},
			})
		)
		.handleAction(
			authLoginAsyncAction.failure,
			(state: any, { payload }: any) => ({
				...state,
				login: {
					loading: false,
					error: payload,
				},
			})
		)
		.handleAction(authProfileAsyncAction.request, (state: any) => ({
			...state,
			login: { ...initialState.login },
		}))
		.handleAction(
			authProfileAsyncAction.success,
			(state: any, { payload }: any) => ({
				...state,
				profile: payload.data,
				permissions: payload.permission,
			})
		)
		.handleAction(
			authProfileAsyncAction.failure,
			(state: any, { payload }: any) => ({
				...state,
				login: {
					loading: false,
					error: payload,
				},
			})
		)
		.handleAction(authLogoutAction.request, (state: any) => ({
			...state,
			...initialState,
		}))
);

export default authReducer;
