import {
	ChangePasswordDto,
	LoginDto,
	ResetPasswordDto,
} from "../../dtos/auth.dto";
import { api, apiResolver } from "../helpers";

import { useMutation, useQuery } from "react-query";
import { useClient } from "../useClient";

export const doLogin = (payload: LoginDto): any =>
	api("auth/admin/login", "POST", payload);

export const doFetchProfile = (token: any): any =>
	api("auth/admin/me", "GET", null, null, token);

/**
 *
 */
export const useForgotPassword = (email: string, options: any) => {
	const client = useClient();

	return useQuery(
		["forget-password"],
		() =>
			client(`/auth/forgetPassword?email=${email}`).then(({ data }) =>
				apiResolver(data)
			),
		{ ...options }
	);
};

export const useResetPassword = (options: any) => {
	const client = useClient();

	return useMutation(
		(body: ResetPasswordDto) =>
			client("/auth/resetPassword", {
				method: "POST",
				data: body,
			}),
		{
			onSuccess: ({ data }) => apiResolver(data),
			...options,
		}
	);
};

export const useSetPassword = (options: any) => {
	const client = useClient();

	return useMutation(
		(body: ResetPasswordDto) =>
			client("/account/SetPassword", {
				method: "POST",
				data: body,
			}),
		{
			onSuccess: ({ data }) => apiResolver(data),
			...options,
		}
	);
};

export const useChangePassword = (options: any) => {
	const client = useClient(false);

	return useMutation(
		(body: ChangePasswordDto) =>
			client("/Authentication/ChangePassword", {
				method: "POST",
				data: body,
			}),
		{
			onSuccess: ({ data }) => apiResolver(data),
			...options,
		}
	);
};

export const useCheckSetPasswordToken = (token: string, options: any) => {
	const client = useClient();

	return useQuery(
		["check-set-password-token"],
		() =>
			client(
				`/account/checkSetPasswordToken?token=${token}`
			).then(({ data }) => apiResolver(data)),
		{ ...options }
	);
};

export const useCheckForgotPasswordToken = (token: string, options: any) => {
	const client = useClient();

	return useQuery(
		["check-forgot-password-token"],
		() =>
			client(
				`/auth/checkforgetPasswordToken?token=${token}`
			).then(({ data }) => apiResolver(data)),
		{ ...options }
	);
};
