import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import routes from "../../routes";
import hasAccess from "../../utils/access-control";
import Loader from "../../components/Loader/Loader";

export const GuardedRoute = ({
	authenticated = false,
	config,
	user,
	component: Component,
	...route
}) => {
	if (authenticated && hasAccess(config.policies, user)) {
		return (
			<Route
				{...route}
				render={(props) => (
					<Component
						{...props}
						policies={config.policies}
						userPermissions={user.permissions}
					/>
				)}
			/>
		);
	}

	return (
		<Redirect
			to={{
				pathname: "/",
			}}
		/>
	);
};

export const PrivateRoutes = ({ authenticated = false, user }) => {
	return (
		<Suspense fallback={<Loader />}>
			<div className="animated fadeIn">
				<Switch>
					{routes.private.map(({ config, ...route }, i) => (
						<GuardedRoute
							key={i.toString(36)}
							authenticated={authenticated}
							config={config}
							user={{
								...user,
								permissions: {
									...user.permissions,
								},
							}}
							{...route}
						/>
					))}
					<Redirect from="/" to="/mood-group-management" />
				</Switch>
			</div>
		</Suspense>
	);
};

export const PublicRoutes = ({ authenticated = false }) => {
	return (
		<Suspense fallback={<Loader />}>
			<Switch>
				{routes.public.map((route, i) => (
					<Route
						key={i.toString(36)}
						path={route.path}
						exact={route.exact}
						render={(props) => {
							if (authenticated) {
								return (
									<Redirect
										to={{
											pathname: "/",
										}}
									/>
								);
							}

							return <route.component {...props} />;
						}}
					/>
				))}

				<Route component={() => <Redirect to="/login" />} />
			</Switch>
		</Suspense>
	);
};
