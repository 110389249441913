import moment from "moment";
import { isArray } from "lodash";
import APIResponseDto from "../dtos/api.dto";
import { apiResolver } from "../services/helpers";
import OptionDto from "../dtos/selectOption.dto";

//check csv ext for fromik validation
export const isCsvExtension = (value) => {
	const name = value.name;
	const lastDot = name.lastIndexOf(".");
	const extension = name.substring(lastDot + 1);
	return extension === "csv";
};

export const isSidebarOpened = () => {
	const rule1 = document.body.classList
		.toString()
		.split(" ")
		.filter((c) => c === "sidebar-lg-show").length;

	const rule2 = document.body.classList
		.toString()
		.split(" ")
		.filter((c) => c === "sidebar-show").length;

	return rule1 + rule2 > 0;
};

/**
 * Check the type of the error and return error in string
 *
 * @param err
 * @returns string
 */
export const extractErrorMsg = (err: any) => {
	if (typeof err === "string") {
		return err;
	}
	if (err?.message && isArray(err.message)) {
		return err.message.join("\n");
	}
	return err.message;
};

export const formatDateTime = (date: Date) =>
	date ? moment(date).format("DD/MM/YYYY HH:mm") : "";

export const formatDateRange = (date: any) => {
	const [from, to] = date.split("~").map((x: string) => x.trim());

	return `${from ? moment(from).format("YYYY-MM-DD") : ""},${
		to ? moment(to).format("YYYY-MM-DD") : ""
	}`;
};

export function dataURLtoFile(dataurl: any, filename: any) {
	const arr = dataurl.split(","),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]);

	let n = bstr.length;
	const u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], filename, { type: mime });
}

export function downloadFile(data: any, filename: any) {
	const url = window.URL.createObjectURL(data.response);
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", filename);
	document.body.appendChild(link);
	link.click();
}

export const actionDetail = {
	loading: false,
	error: "",
	success: false,
};

export const defaultRequestParams = {
	CurrentPage: 1,
	CountPerPage: 20,
};

/**
 * structure data from api to table props
 * return fallback values to table if data was null
 *
 * @param data
 */
export const decorateTableProps = (data: any) => {
	if (!data) {
		return {
			data: [],
			pagination: {
				pageSize: 2,
				totalItems: 0,
				totalPages: 0,
				currentPage: 1,
			},
		};
	}

	return {
		data: data.items,
		pagination: {
			pageSize: data.meta.itemsPerPage,
			totalItems: data.meta.totalItems,
			totalPages: data.meta.totalPages,
			currentPage: data.meta.currentPage,
		},
	};
};

export const transformToSelectOptions = (
	data: Array<any>,
	valueIndex: string = "id",
	labelIndex: string = "name"
): Array<OptionDto> => {
	return data.map((item) => ({
		value: item[valueIndex],
		label: item[labelIndex],
	}));
};

/**
 * get left and top offset of a specific html element
 *
 * @param HTMLELEMENT
 * @returns @Object { top: number, left: number }
 */
export const getOffset = (el: any) => {
	var _x = 0;
	var _y = 0;
	while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
		_x += el.offsetLeft - el.scrollLeft;
		_y += el.offsetTop - el.scrollTop;
		el = el.offsetParent;
	}
	return { top: _y, left: _x };
};

// Generate Timeline
export const generateMomentTimeline = ({
	interval, // moment duration object
	from, // moment duration object
	to, // moment duration object
	unit, // moment duration unit
}) => {
	const durationFrom = moment.duration(from, unit);
	const durationTo = moment.duration(to, unit);

	const values: any = [];

	const getTimelines = (current: any = durationFrom.clone()) => {
		values.push(current);

		const next = current.clone().add(interval);

		if (next.asMilliseconds() < durationTo.asMilliseconds()) {
			getTimelines(next);
		}
	};

	getTimelines();

	return values;
};

export const arrayRepeat = (arr, times) => {
	return new Array(times).fill(arr);
};

/**
 * Helper to render nodes
 *
 * @param value
 * @returns
 */
export const renderJSXNode = (value) => {
	return typeof value === "function" ? value() : value;
};

// Get element's CSS property value
export const getElementStyle = (el, styleProp) => {
	var value,
		defaultView = (el.ownerDocument || document).defaultView;
	// W3C standard way:
	if (defaultView && defaultView.getComputedStyle) {
		// sanitize property name to css notation
		// (hypen separated words eg. font-Size)
		styleProp = styleProp.replace(/([A-Z])/g, "-$1").toLowerCase();
		return defaultView
			.getComputedStyle(el, null)
			.getPropertyValue(styleProp);
	} else if (el.currentStyle) {
		// IE
		// sanitize property name to camelCase
		styleProp = styleProp.replace(/\-(\w)/g, function (str, letter) {
			return letter.toUpperCase();
		});
		value = el.currentStyle[styleProp];
		// convert other units to pixels on IE
		if (/^\d+(em|pt|%|ex)?$/i.test(value)) {
			return (function (value) {
				var oldLeft = el.style.left,
					oldRsLeft = el.runtimeStyle.left;
				el.runtimeStyle.left = el.currentStyle.left;
				el.style.left = value || 0;
				value = el.style.pixelLeft + "px";
				el.style.left = oldLeft;
				el.runtimeStyle.left = oldRsLeft;
				return value;
			})(value);
		}
		return value;
	}
};

/**
 * Get strips tags
 * @param html
 * @returns string
 */
export const stripTags = (html: any) => {
	return html.replace(/<[^>]*>?/gm, "");
};

/**
 * Check null for decimal of float input at Formik
 * @param value { string }
 */
export const checkNullForDecimal = (value: string | undefined) => {
	return parseFloat(value + "") > 0;
};

export const shortWeekDays = [
	"Mon",
	"Tue",
	"Wed",
	"Thu",
	"Fri",
	"Sat",
	"Sun",
	"PH",
];

export const getOnlyWeekdays = shortWeekDays.slice(0, 5);

/**
 *
 * @param {*} startDate     start of the date
 * @param {*} endDate       end of the date
 * @param {*} skipWeekdays  whether you want to calculate weekends as the date range
 */
export const dateRange = (
	startDate: moment.Moment,
	endDate: moment.Moment,
	skipWeekdays?: boolean
): Array<moment.Moment> => {
	const result: Array<moment.Moment> = [];

	const date: moment.Moment = startDate.clone();

	while (date.date() !== endDate.date()) {
		if (skipWeekdays) {
			if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
				result.push(date.clone());
			}
		} else {
			result.push(date.clone());
		}
		date.add(1, "day");
	}

	return result;
};
