import { createAsyncAction } from "typesafe-actions";
import { LoginDto } from "../dtos/auth.dto";
import { doLogin, doFetchProfile } from "../services/auth/auth.service";

// create three kinds of asynchronous actions.request, failure and success
export const authLoginAsyncAction: any = createAsyncAction(
	"AUTH_LOGIN_REQUEST",
	"AUTH_LOGIN_SUCCESS",
	"AUTH_LOGIN_FAILURE"
)<LoginDto, any, Error>();

export const authProfileAsyncAction: any = createAsyncAction(
	"AUTH_PROFILE_REQUEST",
	"AUTH_PROFILE_SUCCESS",
	"AUTH_PROFILE_FAILURE"
)<any, any, Error>();

export const forgetPasswordAsyncAction: any = createAsyncAction(
	"FORGET_PASSWORD_REQUEST",
	"FORGET_PASSWORD_SUCCESS",
	"FORGET_PASSWORD_FAILURE"
)<any, any, Error>();

export const authLogoutAction: any = createAsyncAction(
	"AUTH_LOGOUT_REQUEST",
	"AUTH_LOGOUT_SUCCESS",
	"AUTH_LOGOUT_FAILURE"
)<any, any, Error>();

export const dispatchLogin = (payload: LoginDto): any => (
	dispatch: any
): any => {
	//   ---- mock login ----
	// return dispatch(
	// 	authLoginAsyncAction.success({
	// 		accessToken: "12345",
	// 		profile: {
	// 			role: "Admin",
	// 			email: "admin@rio.co",
	// 			firstName: "admin",
	// 			lastName: "rio",
	// 		},
	// 		refreshToken: "12455",
	// 	})
	// );

	dispatch(authLoginAsyncAction.request());

	doLogin(payload)
		.then(async (res: any) => {
			if (res.data.statusCode !== 0) {
				dispatch(authLoginAsyncAction.failure(res.data.message));
			} else {

				dispatch(authProfileAsyncAction.request());

	    await doFetchProfile(res.data.data.accessToken)
	      .then((res: any) => {
	        if (res.data.statusCode !== 0) {
	          dispatch(authProfileAsyncAction.failure(res.data.message));
	        } else {
	          dispatch(authProfileAsyncAction.success(res.data));
	        }
	      })
	      .catch((error: any) => {
	        dispatch(authProfileAsyncAction.failure(error.response.data.message));
	      });

	      dispatch(authLoginAsyncAction.success(res.data.data));
			}
		})
		.catch((error: any) => {
			dispatch(authLoginAsyncAction.failure(error));
		});
};

export const dispatchLogout = () => (dispatch: any) =>
	dispatch(authLogoutAction.request());

// Create logout action
