/**
 * Routes generator
 *
 * IMPORTANT:
 *  - Component name defined in `views/index.ts` must be equal to the name
 *	defined in `config/routes.ts`
 */

import * as views from "./views/index";
import routeConfig from "./config/route";

import { Routes, Route } from "./dtos/route.dto";

// remove spaces from Route name
const stripSpaces = (text: string): string => text.replaceAll(/\s/g, "");

const routes: Routes = Object.keys(routeConfig.routes).reduce(
	(a: Routes, b) => {
		const routeCollection: Array<Route> = routeConfig.routes[b];

		a[b] = routeCollection.map((route) => {
			return {
				...route,
				component: views[stripSpaces(route.name)],
			};
		});

		return a;
	},
	{
		public: [],
		private: [],
	}
);

export default routes;
